<template>
  <vue-final-modal class="modal-pwa-install-guide">
    <div class="vfm__head">
      <div class="vfm__control vfm__control-left" />

      <div class="vfm__title">
        {{ getLabel('title') }}
      </div>

      <div class="vfm__control vfm__control-right">
        <atomic-icon id="close" class="vfm__close" @click="closeModal('pwa-install-guide')" />
      </div>
    </div>

    <div class="vfm__body">
      <div class="guide__icon-wrapper">
        <atomic-icon id="pwa-guide-logo" filled />
      </div>
      <div class="guide__content">
        <div class="guide__header">
          {{ getLabel('header') }}
        </div>
        <ol class="guide__list">
          <template v-if="isIOS">
            <li
              v-html="DOMPurify.sanitize(marked.parseInline(iosFirstItemHTML) as string, { FORBID_TAGS: ['style'] })"
            />
            <li
              v-html="DOMPurify.sanitize(marked.parseInline(iosSecondItemHTML) as string, { FORBID_TAGS: ['style'] })"
            />
          </template>
          <template v-if="platform === 'macOS'">
            <li
              v-html="DOMPurify.sanitize(marked.parseInline(macosFirstItemHTML) as string, { FORBID_TAGS: ['style'] })"
            />
            <li
              v-html="DOMPurify.sanitize(marked.parseInline(macosSecondItemHTML) as string, { FORBID_TAGS: ['style'] })"
            />
            <li
              v-html="DOMPurify.sanitize(marked.parseInline(macosThirdItemHTML) as string, { FORBID_TAGS: ['style'] })"
            />
          </template>

          <li
            v-if="!isDuplicate"
            class="bonus-item"
            v-html="DOMPurify.sanitize(marked.parseInline(bonusItemHTML) as string, { FORBID_TAGS: ['style'] })"
          />
        </ol>
        <video v-lazy-load  class="pwa-instruction-video" autoplay loop preload="auto" playsinline :data-src="videoSrc"></video>
      </div>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import addSvg from 'assets/icons/empty/add.svg?raw';
  import installDesktopSvg from 'assets/icons/empty/install-desktop.svg?raw';
  import shareSvg from 'assets/icons/empty/share.svg?raw';
  import dockSvg from 'assets/icons/empty/dock.svg?raw';
  import { VueFinalModal } from 'vue-final-modal';
  import type { CIModalsContent } from '~/types';
  import DOMPurify from 'isomorphic-dompurify';
  import { marked } from 'marked';

  const props = defineProps<{
    platform: 'iOS' | 'macOS';
    browser: 'Mobile Chrome' | 'Mobile Safari' | 'Safari';
    isDuplicate?: boolean;
    currentLocaleData?: Maybe<CIModalsContent['pwaInstallGuide']>;
    defaultLocaleData?: Maybe<CIModalsContent['pwaInstallGuide']>;
  }>();
  const { getContent } = useProjectMethods();

  const getLabel = (path: string): string => getContent(props.currentLocaleData, props.defaultLocaleData, path);

  const isIOS = computed(() => props.platform === 'iOS');
  const isIOSChrome = computed(() => props.browser === 'Mobile Chrome');

  const iosFirstItemHTML = computed(() =>
    getLabel(`ios.${isIOSChrome.value ? 'firstItemLabel' : 'chromeFirstItemLabel'}`).replace(
      '{icon}',
      `<div class="svg-icon">${shareSvg}</div>`
    )
  );
  const iosSecondItemHTML = computed(() =>
    getLabel('ios.secondItemLabel').replace(
      '{button}',
      `<div class="inline-button">${getLabel('ios.secondButton')} ${addSvg}</div>`
    )
  );

  const macosFirstItemHTML = computed(() =>
    getLabel('macos.firstItemLabel').replace('{icon}', `<div class="svg-icon install-icon">${installDesktopSvg}</div>`)
  );
  const macosSecondItemHTML = computed(() =>
    getLabel('macos.secondItemLabel')
      .replace('{button}', `<div class="inline-button">${getLabel('macos.secondButton')} ${dockSvg}</div>`)
      .replace('{icon}', `<div class="svg-icon">${shareSvg}</div>`)
  );
  const macosThirdItemHTML = computed(() =>
    getLabel('macos.thirdItemLabel').replace(
      '{button}',
      `<div class="inline-button">${getLabel('macos.thirdButton')}</div>`
    )
  );

  const bonusItemHTML = computed(() =>
    getLabel('bonusItem').replace(
      '{bonusItemGoldenPart}',
      `<span class="golden-part">${getLabel('bonusItemGoldenPart')}</span>`
    )
  );

  const videoSrc = computed(() =>
    getLabel(isIOSChrome.value ? 'ios.chromeVideoSrc' : isIOS.value ? 'ios.videoSrc' : 'macos.videoSrc')
  );

  const { closeModal } = useModalStore();
</script>
<style lang="scss" src="~/assets/styles/components/modal/pwa-install-guide.scss" />
